import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from "../environments/environment";
import {GoogleAnalyticsService} from "./services/google-analytics/google-analytics.service";
import {AiChatService} from "./services/ai-chat/ai-chat.service";
import {UserFacade} from "./core/store/facade/user.facade";
import {filter, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {TEACHER_SUBJECT_IDS} from "./models/const/variables";

@Component({
    selector: 'my-gwo-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    providers: [
        AiChatService,
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Moje-GWO';

    readonly userSubscription: Subscription = this.userFacade.user$.pipe(
        filter((user) => !!user?.teacher_subjected_levels && environment.aiChatSettings.isActive),
        map((user) => user?.teacher_subjected_levels?.includes(TEACHER_SUBJECT_IDS.HISTORY))
    ).subscribe((isAiChatAvailable) => isAiChatAvailable && this.aiChatService.initialize());

    constructor(
        private readonly googleAnalyticsService: GoogleAnalyticsService,
        private readonly aiChatService: AiChatService,
        private readonly userFacade: UserFacade,
    ) {
    }

    ngOnInit(): void {
        if (environment.production) {
            this.googleAnalyticsService.initialize();
        }
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }
}
