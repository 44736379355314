import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AuthService} from "../../../services/auth/auth.service";
import {filter, first, switchMap, tap} from "rxjs";
import {EventsActions, NewsActions, UserActions} from "../actions";
import {catchError, map} from "rxjs/operators";
import {ErrorFacade} from "../facade/error.facade";
import {AuthRedirectService} from "../../../services/auth-redirect.service";
import {AnnouncementModel, NotificationModel} from "../../../models/interfaces/notification-model";
import {UserFacade} from "../facade/user.facade";
import {ALARM_MESSAGE_ID, EVENT_NOTIFY_ID} from "../../../models/const/variables";

@Injectable({providedIn: 'root'})
export class UserEffects {
    readonly initUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.initUser),
        tap(() => this.userFacade.setLoading(true)),
        switchMap(() => this.authService.getUserData().pipe(
            catchError((error) => {
                this.errorFacade.onShowError(error);
                this.userFacade.setLoading(false);
                throw error;
            }),
            map((user) => UserActions.initAuthUserData({user})),
            tap(() => this.userFacade.setLoading(false))
        )),
    ));

    readonly getDictionary$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.initAuthUserData),
        first(),
        switchMap(() => this.authService.getDictionary().pipe(
            catchError((error) => {
                this.errorFacade.onShowError(error);
                throw error;
            }),
            map((dictionary) => UserActions.setDictionary({dictionary}))
        ))
    ));

    readonly getUserAnnouncement$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.initUser),
        first(),
        switchMap(() => this.authService.getUserAnnouncement().pipe(
            filter((data) => !!data?.data?.length),
            map(({data}) => data),
            map((data) => {
                const alarmNotify: AnnouncementModel[] = data.filter((notify) => notify.type === ALARM_MESSAGE_ID);

                if (alarmNotify.length) {
                    const notification = new NotificationModel(alarmNotify, false, false, true, true);
                    this.userFacade.setNotification(notification);
                }

                return data.filter((notify) => notify.type !== ALARM_MESSAGE_ID) as AnnouncementModel[];
            }),
            map((data) => {
                const eventNotify: AnnouncementModel[] = data.filter((notify) => notify.type === EVENT_NOTIFY_ID);

                if (eventNotify.length) {
                    const notification = new NotificationModel(eventNotify, true, true, true, false, true);
                    this.userFacade.setNotification(notification);
                }

                return data.filter((notify) => notify.type !== EVENT_NOTIFY_ID) as AnnouncementModel[];
            }),
            filter((data) => !!data.length),
            map((data) => {
                const notification = new NotificationModel(data, false, false, true);

                return UserActions.setNotification({notification});
            })
        ))
    ));

    readonly initUserMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.initAuthUserData),
            map(() => NewsActions.initNews())
        )
    );

    readonly initUserSimpleCalendarEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.initAuthUserData),
            map((action) => {
                if (action.user.is_quasi_user) {
                    // If user is a quasi-user, dispatch addCalendarEvent with null data
                    return EventsActions.addSidebarCalendarEvent({eventsData: []});
                } else {
                    return EventsActions.initSidebarCalendarEvents();
                }
            })
        )
    );

    readonly updateUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.refreshUser),
        switchMap(() => this.authService.getUserData().pipe(
            map((user) => UserActions.updateUser({user})),
        )),
    ));

    readonly removeAuthToken$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.setLoggedOut),
        tap(() => this.authRedirectService.redirectToLogOut()),
        first(),
    ), {dispatch: false});


    constructor(
        private readonly actions$: Actions,
        private readonly authService: AuthService,
        private readonly authRedirectService: AuthRedirectService,
        private readonly errorFacade: ErrorFacade,
        private readonly userFacade: UserFacade,
    ) {
    }
}
