import {environment} from "../../../environments/environment";

export class AiChatService {
    private readonly chatSettings = environment.aiChatSettings;

    constructor() {
    }

    public initialize(): void {
        try {
            this.loadChatLib();
            this.loadChatConf();
        } catch (e) {
            console.error('Error adding AI chat on page', e);
        }
    }

    private loadChatLib(): void {
        const aiChatScript = document.createElement('script');
        aiChatScript.src = this.chatSettings.chatLink + 'bubble-embed.js';
        document.body.appendChild(aiChatScript);
    }

    private loadChatConf(): void {
        const aiChatEmbed = document.createElement('script');
        aiChatEmbed.innerHTML = `window.danteEmbed = "${this.chatSettings.chatLink}embed/?kb_id=${this.chatSettings.chatId}&token=${this.chatSettings.chatToken}&modeltype=${this.chatSettings.chatModelType}&mode=${this.chatSettings.chatMode}&logo=${this.chatSettings.chatLogo}&bubble=true&bubbleopen=false"`;
        document.body.appendChild(aiChatEmbed);
    }
}
