import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {AppModule} from "./app/app.module";
import * as Sentry from "@sentry/angular";
import packageJson from "../package.json";
import {environment} from "./environments/environment";

Sentry.init({
    release: "moje-gwo@" + packageJson.version,
    dsn: environment.sentryUrl,
    environment: environment.production ? 'production' : 'test',
    debug: false,
    enabled: environment.useSentry,
    sendClientReports: false,
    ignoreErrors: [
        /CORS/i,
    ],
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
